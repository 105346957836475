import React, { useState, useEffect } from 'react';
import './Footer.css';

const Footer = () => {
    const [runtime, setRuntime] = useState('');
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {
        const startTime = new Date('2024-06-30T00:00:00');
        const updateRuntime = () => {
            const now = new Date();
            const diff = now - startTime;
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((diff / 1000 / 60) % 60);
            const seconds = Math.floor((diff / 1000) % 60);
            setRuntime(`${days} 天 ${hours} 时 ${minutes} 分 ${seconds} 秒`);
        };

        const interval = setInterval(updateRuntime, 1000);
        updateRuntime();

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://hm.baidu.com/hm.js?e0bb4a9a7e4556ea3d08c51e367c6fc6'; // Replace with your Baidu Analytics code
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="stats-row">
                    {/* <div className="stat-box stat-views">
                        访问量: <span id="totalPageViews">0</span>
                    </div>
                    <div className="stat-box stat-visitors">
                        访客量: <span id="todayVisitors">0</span>
                    </div>
                    <div className="stat-box stat-ips">
                        IP 数: <span id="todayIPs">0</span>
                    </div> */}
                    <div className="stat-box runtime-box">
                        已运行: {runtime} ღゝ◡╹)ノ♡
                    </div>
                </div>
                <div className="copyright-row">
                    <p>© {currentYear} <svg className="inline-block w-4 h-4 mx-1 font-thin" xmlns="http://www.w3.org/2000/svg" fill="#EF3E36" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"></path>
                        </svg> <a className="underline" href="https://hub.openeeds.com" target="_blank" rel="noopener noreferrer">yanjh.</a> Hosted on Serverless.
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
