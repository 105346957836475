import { useState } from "react";
import { useDispatch } from "react-redux";
import { clearEntity, generateShortUrl } from "../reducers/entityReducer";
import { clearError, setError } from "../reducers/errorReducer";
import Button from "./Button";
import Error from "./Error";

const Search = () => {
    const dispatch = useDispatch();
    const [url, setUrl] = useState('');
    // 处理按键事件
    const handleClick = () => {
        dispatch(clearError());
        dispatch(clearEntity());
        if (!/^((https|http)?:\/\/)[^\s]+/.test(encodeURI(url))) {
            dispatch(setError('URL 格式有误，请输入 http:// 或 https:// 开头的网址'));
        } else {
            dispatch(generateShortUrl(url));
            setUrl('');
        }
    }
    // 监听回车事件
    const handleKeyup = (event) => {
        if (event.keyCode === 13) {
            handleClick();
        }
    }
    return (
        <div className="mb-4 px-4 md:px-0">
            <div className="flex flex-col items-center md:flex-row md:justify-center md:items-start space-y-2 md:space-y-0">
                <div className="w-full md:w-3/5 mb-1 md:mb-0 md:mr-4">
                    <input
                        className="outline-none w-full px-5 py-3 rounded-lg font-mono text-base md:text-lg text-black bg-yellow-100 bg-opacity-80 placeholder-yellow-900 placeholder-opacity-30"
                        placeholder="请输入 http:// 或 https:// 开头的网址"
                        value={url}
                        onChange={({ target }) => setUrl(target.value)}
                        onKeyUp={handleKeyup}
                    />
                    <Error />
                </div>
                <div className="w-full md:w-auto flex justify-center md:justify-start mb-1 md:mb-0">
                    <Button label=")一键压缩(" onClick={handleClick} />
                </div>
            </div>
        </div>
    );
}

export default Search;
